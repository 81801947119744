import React from 'react';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  defaultAppComponents,
  bindDefaultRoutes,
  routes as defaultRoutes,
  ConfigToFeatureFlagHandlerProps,
  ConfigToFeatureFlagHandler,
  VisitListener,
} from '@platt-internal/backstage-plugin-bootstrap-react';
import {
  bindRepositoryCatalogRoutes,
  repositoryCatalogAppRoutes
} from '@platt/backstage-plugin-bootstrap-repository-catalog-react';
import { faqsAppRoutes } from '@platt/backstage-plugin-bootstrap-faqs-react';
import { onboardingDemoRoutes } from '@platt/backstage-plugin-onboarding-demo';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';

const app = createApp({
  apis,
  components: {  
    ...defaultAppComponents,
  },
  bindRoutes({ bind }) {  
    bindDefaultRoutes({ bind });
    bindRepositoryCatalogRoutes({ bind });
  },
  featureFlags: [
    {
      pluginId: '',
      name: 'repository-catalog',
      description: 'Enables the repository issue graph',
    },
    {
      pluginId: '',
      name: 'minute-graph',
      description: 'Enables the minute graph',
    }
  ]
});

const routes = (
  <FlatRoutes>
    {defaultRoutes({ entityPage })}

    {/* additional routes */}
    {faqsAppRoutes()}
    {repositoryCatalogAppRoutes()}
    {onboardingDemoRoutes()}
  </FlatRoutes>

);

const configHandlersProps: ConfigToFeatureFlagHandlerProps = {
  checks: [
    {
      isEnable: config => config.getOptionalBoolean('spotify.enabled') ?? false,
      featureFlagName: 'spotify-plugins',
    }
  ]
}

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <ConfigToFeatureFlagHandler {...configHandlersProps} />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
